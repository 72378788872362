// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/login/Register.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/login/Register.tsx");
}
// REMIX HMR END

import { Alert, AlertDescription, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, Container, FormControl, FormErrorMessage, FormLabel, Heading, Input, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useColorMode, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useFetcher, useNavigate } from "@remix-run/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { DealbunnyIcon, DealbunnyIconNoGradient } from "../customIcons";
import { OAuthButtonGroup } from "./OAuthButtonGroup";
import { PasswordField } from "./PasswordField";
import loginContainer from "~/styles/loginStyle.css";
import { useAuth } from "~/utils/userUtils";
export const registerLinks = () => [{
  rel: "stylesheet",
  href: loginContainer
}];
const validationSchema = Yup.object({
  username: Yup.string().min(4, "Minimum 4 Zeichen").matches(/^\S.*\S$/, "Keine Leerzeichen am Anfang oder Ende erlaubt").matches(/^[^@]*$/, "Username darf kein @ Zeichen enthalten").required("Pflichtfeld"),
  email: Yup.string().email("E-Mail ungültig").required("Pflichtfeld"),
  password: Yup.string().min(6, "Minimum 6 Zeichen").required("Pflichtfeld"),
  password2: Yup.string().oneOf([Yup.ref("password")], "Passwörter sind nicht identisch.")
});
const Register = props => {
  _s();
  const {
    isModal
  } = props;
  const {
    user,
    login,
    logout
  } = useAuth();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const [isLogged, setIsLogged] = useState(false);
  const [text, setText] = useState("");
  const [buttons, setButtons] = useState(<></>);
  const navigate = useNavigate();
  const {
    colorMode,
    toggleColorMode
  } = useColorMode();
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpenOnSuccess, setIsOpenOnSuccess] = useState(false);
  const loginFetcher = useFetcher();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [countdown, setCountdown] = useState(null);
  const cancelRef = useRef(null);
  const logoutHandler = e => {
    e.preventDefault();
    logout();
    setIsLogged(false);
    setText("Du wurdest erfolgreich ausgeloggt. Bis bald...");
    setTimeout(() => navigate("/"), 1000); // Redirect to homepage after 3 sec
  };

  const loginHandler = e => {

    // console.log(window.ENV);
  };
  useEffect(() => {
    setIsLogged(!!user);
    setButtons(<OAuthButtonGroup />);
    const handleCountdown = value => {
      // console.log(countdown);
      if (value > 0) {
        setCountdown(value);
        setTimeout(() => handleCountdown(value - 1), 1000);
      } else {
        setCountdown(null);
      }
    };
    if (loginFetcher.state === "idle" && loginFetcher.data && countdown == null && loading) {
      // userDetails.load(`/users/${user.id}/details`);
      // console.log(loginFetcher.data);
      if (loginFetcher.data) {
        const response = loginFetcher.data;
        if (response.error) {
          console.log(response.error);
          if (response.error.status == 400) {
            setError("E-Mail oder Benutzername bereits registriert!");
            handleCountdown(3);
          }
        } else {
          // console.log(response.jwt);
          // login({
          //   ...response.user,
          //   authToken: response.jwt,
          // });

          setIsOpenOnSuccess(true);
          setSuccess("Deine Registrierung war erfolgreich. Bitte bestätige noch deine E-Mail.");
          setLoading(false);
          setTimeout(() => {
            navigate("/");
          }, 10000); // Redirect to homepage after 3 sec
        }
      }

      setLoading(false);
    }
  }, [user, loginFetcher, countdown]);
  const isError = input === "";
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      password2: "",
      username: ""
    },
    validationSchema,
    onSubmit: values => {
      if (countdown && countdown > 0 || loading || success != "") {
        return;
      }
      setLoading(true);
      setError("");
      setSuccess("");
      // console.log("Form data", values);
      loginFetcher.submit(values, {
        action: `/login/register`,
        method: "POST"
      });
    }
  });
  return <>
      <Box className="loginContainer">
        <Container w={{
        base: "350px",
        md: "auto"
      }} maxW={{
        base: "90%",
        md: "xl"
      }}
      // py={{ base: "0", md: "24" }}
      // px={{ base: "0", md: "8" }}
      >
          <Stack spacing="8" bgColor={isModal ? "transparent" : useColorModeValue("rgba(255,255,255,0.7)", "rgba(0,0,0,0.7)")} px="3" py={isModal ? 8 : {
          base: "10",
          md: "10"
        }}
        // bg={{ base: "transparent", sm: "bg.surface" }}
        boxShadow={isModal ? 0 : {
          base: "lg",
          sm: "xl"
        }} borderRadius={{
          base: "xl",
          sm: "2xl"
        }}>

            <Stack spacing="6">
              {colorMode == "dark" && <DealbunnyIconNoGradient fill={"white"} minW={"100%"} minH={"40px"} mb={{
              base: "10px",
              lg: "50px"
            }} />}


              {colorMode != "dark" && <DealbunnyIcon fill={"white"} minW={"100%"} minH={"50px"} mb={{
              base: "10px",
              lg: "50px"
            }} />}


              <Stack spacing={{
              base: "2",
              md: "3"
            }} textAlign="center">
                <Heading size={{
                base: "md",
                md: "lg"
              }}>
                  Willkommen in der dealbunny Community 🎉
                </Heading>
                <Text color="fg.muted">
                  Du bist bereits registriert?{" "}
                  <Link textDecoration={"underline"} href="/login">
                    Hier anmelden
                  </Link>
                </Text>
              </Stack>
            </Stack>
            <Box py={isModal ? {
            base: "0",
            lg: "0"
          } : {
            base: "0",
            lg: "10"
          }} px={isModal ? {
            base: "3",
            lg: "10"
          } : {
            base: "8",
            lg: "16"
          }}>

              <loginFetcher.Form method="post" onSubmit={formik.handleSubmit}>
                <Stack spacing="6">
                  <Stack spacing="5">
                    <FormControl isRequired isInvalid={!!(formik.touched.username && formik.errors.username)}>


                      <FormLabel htmlFor="username">Benutzername</FormLabel>
                      <Input id="username" name="username" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username} />

                      <FormErrorMessage>
                        {formik.errors.username}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!(formik.touched.email && formik.errors.email)}>


                      <FormLabel htmlFor="email">E-Mail</FormLabel>
                      <Input id="email" name="email" type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />

                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>
                    <PasswordField name="password" id="password" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} error={formik.touched.password && formik.errors.password ? formik.errors.password : undefined} />


                    <PasswordField name="password2" id="password2" passwordtitle="Passwort wiederholen" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password2} error={formik.touched.password2 && formik.errors.password2 ? formik.errors.password2 : undefined} />


                  </Stack>
                  <Stack spacing="6">
                    <Button type="submit" disabled={loading || success != ""} isLoading={loading} loadingText="Registrierung läuft" colorScheme="pink">

                      {countdown == null && "Registrieren"}
                      {!!(countdown && countdown > 0) && `Bitte warten ... ${countdown}`}
                    </Button>
                    {error != "" && <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>{error}</AlertDescription>
                      </Alert>}

                    {success != "" && <Alert status="success">
                        <AlertIcon />
                        {success}
                      </Alert>}

                  </Stack>
                </Stack>
              </loginFetcher.Form>
            </Box>
          </Stack>
        </Container>
      </Box>
      <AlertDialog isOpen={isOpenOnSuccess} leastDestructiveRef={cancelRef} onClose={onClose}>

        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deine Registrierung war erfolgreich. 
            </AlertDialogHeader>

            <AlertDialogBody>
              Fast geschafft 👍🏻 Bitte bestätige noch deine E-Mail.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="red" onClick={e => {
              navigate("/");
              onClose();
            }} ml={3}>

                Startseite
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal closeOnOverlayClick={false} isOpen={isLogged} onClose={onClose} isCentered>

        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Du bist bereits eingeloggt</ModalHeader>
          <ModalBody pb={6}>{text}</ModalBody>

          <ModalFooter>
            <Link href="/">
              <Button colorScheme="pink" mr={3}>
                Startseite
              </Button>
            </Link>
            <Button onClick={logoutHandler}>Logout</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>;
};
_s(Register, "ZcNMRl0B5YmAAfuaUyL436G2DSM=", false, function () {
  return [useAuth, useDisclosure, useNavigate, useColorMode, useFetcher, useFormik, useColorModeValue];
});
_c = Register;
export default Register;
var _c;
$RefreshReg$(_c, "Register");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;